@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.requestContainer {
    display: flex;
    justify-content: space-between;
}

.requestRightContainer {
    display: flex;
    padding-right: 20px;
    align-items: center;
}

.paddingGap {
    padding-left: 25px;
    cursor: pointer;
}

.contactRequestContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    width: 290px;
    border-radius: 200px;
    background-color: #faf9f9;
}

.contactRequestContainerHover {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    width: 290px;
    border-radius: 200px;
    background-color: white;
    box-shadow: 0px -2px 11px 0px #eaeaea;
}

.contactRequestHeading {
    font-weight: bold;
    color: #1b5fea;
    padding-left: 10px;
    cursor: pointer;
}

.myUserContainerInbox {
    display: flex;
    align-items: center;
}

.centerAlign {
    display: flex;
    justify-content: center;
    background-color: #faf9f9;
}

.selected {
    color: white;
}

.contactRequestRightArrow {
    color: $blue-primary;
}

.padding {
    padding: 10px;
}

.roundImageMessageScreen {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.roundImageList {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
}
