@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.heading {
    font-weight: bold;
    font-size: 1.4rem;
    padding-left: 10px;
}

.roundName {
    height: 32px;
    width: 32px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.roundNameSelected {
    height: 55px;
    width: 55px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.requestMiddleContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 0.9rem;
}

.bold {
    font-weight: bold;
}

.name {
    font-weight: bold;
    color: grey;
    font-size: 0.7rem;
}

.messagePreview {
    color: grey;
}

.cursorer {
    cursor: pointer;
}

.inboxContainer {
    height: 100vh;
    overflow-y: scroll;
    // overflow: hidden;
    width: 300px;
    float: left;
    background-color: #f5f5f5;
    // height: 45px;
}

.inboxSubContainer {
    display: flex;
    justify-content: space-between;
    padding: 18px 13px 18px 13px;
}

.emptyInboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.inboxHeaderHeading {
    font-weight: bold;
    font-size: 1.4rem;
}

.userTabContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 5px;
    background-color: #1b5fea;
    color: white;
    cursor: pointer;
}

.userTabContainerUn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 5px;
    background-color: #faf9f9;
    cursor: pointer;
    border-bottom: 1px solid #d8d8d8;
}

.containerPadding {
    padding: 10px;
}

.rightSideInboxUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.8rem;
    padding-right: 5px;
}

.coloringTabSelect {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #1b5fea;
}

.coloringTabUnSelect {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ececec;
}

/**
Messages Container
**/

.messageContainerHeader {
    background-color: #fefefe;
    // height: 13vh;
    width: 100%;
    padding: 5px;
    box-shadow: 0px 2px 4px #d9d9d954;
}

.messagesContainers {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

.messageLoadingContainer {
    align-self: flex-end;
    padding-right: 20px;
}

.mcHeaderSetting {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mcHeaderLeft {
    display: flex;
    align-items: center;
    padding: 10px;
}

.mcHeaderRight {
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
}

.messagesContainerBody {
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
}

.messageContainerFooter {
    margin-top: auto;
    background-color: #fefefe;
    height: auto;
    padding-top: 10px;
    padding-bottom: 15px;
    width: 100%;
    box-shadow: 0px 2px 4px #d9d9d954;
}

.flexContainer {
    display: flex;
}

.flexExtendToEnd {
    flex-grow: 1;
}

.inputContainer {
    width: 99%;
    display: flex;
    justify-content: center;
    background-color: none;
    border: 1px solid #191716cc;
    padding-left: 16px;
    border-radius: 50px;
    align-items: center;
}

.inputMessage {
    height: 100%;
}

.inputField {
    outline: none;
    border: none;
    color: #191716cc;
    width: 100%;
    font-size: 1.05rem;
    padding-right: 10px;
}

.sendCameraIcon {
    background: #1b5fea;
    color: white;
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 100%;

    svg {
        margin-top: 5px;
    }
}

.sendMessageIcon {
    background: #1b5fea;
    color: white;
    padding: 3px 7px;
    border-radius: 100%;
    margin-right: 2px;

    svg {
        margin-top: 5px;
    }
}

.newMessages {
    position: absolute;
    bottom: 80px;
    right: 20px;
    color: white;
    font-weight: 10px;
    font-weight: bold;
    background-color: #1b5fea;
    border-radius: 50px;
    font-size: 10px;
    padding: 10px;
    box-shadow: 0px -1px 17px 0px rgba(12, 43, 233, 0.74);
}

.infoSection {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.iconStyling {
    cursor: pointer;
    margin-right: 5px;
}

.infoBody {
    padding: 10px;
    padding-top: 20px;
}

.nameHeadingStyle {
    padding: 8px;
    color: black;
    font-weight: bold;
}

.scrollSectionMedia {
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
}

.box {
    min-width: 100px;
    min-height: 100px;
    margin: 10px;
    background-color: #d3d3d3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placingAtBottom {
    margin-top: auto;
}

.infobottomSection {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.infoBottomButtonContainer {
    display: flex;
    cursor: pointer;
    padding: 5px;
    align-items: center;
}

.greyIconColor {
    color: #777676;
}

.removeHeading {
    padding-left: 10px;
    color: #777676;
    font-weight: bold;
    cursor: pointer;
}

.redIconColor {
    color: #ec0819;
}

.reportHeading {
    padding-left: 10px;
    color: #ec0819;
    font-weight: bold;
    cursor: pointer;
}

.italic {
    font-style: italic;
}

.roundImageMessageScreen {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
}

.thumbnailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.thumbnailTime {
    color: $gray-medium;
    font-size: 12px;
    font-weight: bold;
}
