@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.greyText {
    font-size: 13px;
    color: $gray-medium;
    letter-spacing: -0.21px;
    &.bold {
        font-weight: bold;
    }

    &.semiTransparent {
        color: $black1;
        opacity: 40%;
        &.bold {
            color: $black-text;
        }
    }
    &.block {
        display: block;
    }
    &.center {
        display: block;
        text-align: center;
    }
    &.clickable {
        @include generic-hover;
    }
}
