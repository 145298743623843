@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.settingPanel {
    @include setting-panel;
    @include generic-hover;
    letter-spacing: -0.51px;
    font-size: 17px;
    border-bottom: 1px solid $white4;
    cursor: pointer;
}

.panelLeft {
    display: flex;
    align-items: center;
}

.panelChevron {
    color: #dadada;
    height: 22px;
    width: 22px;
    stroke-width: 2.5px;
    opacity: 0.8;
}

.panelIcon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}
