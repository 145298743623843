@import '../../../styles/variables.scss';

.bottomText {
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.21px;
}

.emailCircle {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: $blue-primary;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    svg {
        stroke-width: 1.5px;
    }
}
