@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    height: 51px;
    color: $white1;
    margin: 8px auto;
    padding: 0px 32px;
    border-width: 0;
    font-size: 17px;
    letter-spacing: -0.51px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline: 0; // Might want to reconsider for a11y reasons

    @include generic-hover;
    @include button-themes;
}

.fullWidth {
    width: 100%;
}

.inline {
    display: inline-flex;
}

.small {
    height: 36px;
    font-size: 14px;
    padding: 0px 20px;
    margin: 0;
}

.iconButton {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: 'space-between';
    span {
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;
    }
}
