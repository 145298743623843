@import '../../../styles/variables.scss';

.bottomText {
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.21px;
}

.passwordCriteria {
    font-size: 14px;
    color: $black1;
    letter-spacing: -0.21px;
}
