@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.outerCircle {
    @include generic-hover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px 0;
    cursor: pointer;
}

.innerCircle {
    background-color: inherit;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    box-sizing: content-box;

    &.selected {
        border: 2px solid $white1;
    }
}
