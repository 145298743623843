@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.paymentLogoContainer {
    display: flex;
    justify-content: center;
    img {
        margin: 0 20px;
        cursor: pointer;
        @include generic-hover;
    }
}

.inlineInputContainer {
    display: flex;
    margin: -8px 0; // The wrapper div makes it so that the contained inputs and the inputs above both have margin. Counteract that
}

.center {
    display: flex;
    justify-content: center;
}
