@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.doneButton {
    margin-top: 30px;
    width: 100%;
}

.whereToSendOption {
    @include generic-hover;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: $blue-primary;
    font-weight: bold;

    svg {
        margin-right: 10px;
    }
}

.panelContentsWrapper {
    min-width: 425px;
}

.panelTitle {
    text-align: left;
}

.listUserIcon {
    background-color: $white4;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-medium;
    font-size: 14px;
    margin-right: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 12px;
}

.listUserListContainer {
    max-height: 500px;
    overflow: auto;
}

.listUserContainer {
    display: flex;
    align-items: center;
    padding: 6px 0;
    padding-right: 8px;

    &:not(:last-of-type) {
        border-bottom: 1px solid $white4;
    }
}

.subItemListUserContainer {
    padding-left: 30px;
    background-color: $white3;
}

.listUserName {
    flex: 1;
    text-align: left;
    margin-top: 12px;
}

.sendButton {
    height: 36px;
    padding: 0 20px;
}

.sendProgressWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.progress {
    height: 12px;
    width: 100%;
    font-size: 12px;
}
