.container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 300;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
