.tabContainer {
    display: flex;
    justify-content: space-around;
    font-size: 0.95rem;
    margin-top: 10px;
}
.active {
    font-weight: bold;
    color: black;
    cursor: pointer;
    padding: 8px;
    font-size: 14px;
    background-color: #faf9f9;
    border-radius: 1px;
    border-top: 1px solid #e6e3e3;
    border-left: 1px solid #e6e3e3;
    border-right: 1px solid #e6e3e3;
    // box-shadow: 0px -2px 11px 0px #eaeaea;
}

.inActive {
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    color: #777676;
    cursor: pointer;
    background-color: #f5f5f5;
}
