@import '../../styles/mixins.scss';

.page {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.webcamVideoDisplay {
    width: 100%;
    height: 100%;
    position: absolute;
}

.webcamVideoDisplayContainer {
    position: relative;
}

.webcamVideoDisplayBorder {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    background-color: transparent;
    transition: background-color 0.7s;

    &.recording {
        background-color: red;
    }
}

.recordButtonsContainer {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.recorderCanvas {
    @include stackable-canvas;
    visibility: hidden;
    pointer-events: none;
}
