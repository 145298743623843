@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.backLink {
    color: $white1 !important;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    margin-bottom: 16px;
    font-size: 14px;
    opacity: 0.87;

    @include generic-hover;
}

.panel {
    position: relative;
    background-color: $white1;
    border-radius: 18px;
    padding: 40px calc(min(80px, 10vw));
    box-shadow: 0px 5px 10px #0e3075;
    max-width: calc(min(503px, 100vw));
    color: $black-text;
    @media only screen and (max-width: 500px) {
        margin-left: 16px;
        margin-right: 16px;
        padding: 20px;
    }
}
