@import '../../styles/variables.scss';

.inputContainer {
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.inputIcon {
    margin: 10px;
    text-align: center;
    cursor: pointer;
    color: $gray-medium;
}

.input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    font-size: 17px;
    &::placeholder {
        color: $gray-medium;
    }
}
