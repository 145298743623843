@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.roundNameBigger {
    height: 50px;
    width: 50px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    object-fit: cover;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.organizationTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    padding-bottom: 5px;
    background-color: #faf9f9;
}

.organizationTabForIndivdual {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    padding-bottom: 5px;
}

.organizationName {
    font-size: 0.8rem;
    font-weight: bold;
    padding-left: 10px;
}

.backButtonContainer {
    display: flex;
    align-items: center;
    padding: 15px 0px 5px 10px;
    background-color: #faf9f9;
    cursor: pointer;
}

.backButton {
    color: grey;
    padding-left: 3px;
    cursor: pointer;
}

.groupNameContainer {
    display: flex;
    align-items: center;
    padding: 15px 0px 5px 15px;
    background-color: #faf9f9;
}

.groupBiggerHeading {
    padding-left: 10px;
    font-size: 1.3rem;
    font-weight: bold;
}

.selected {
    font-style: italic;
    color: white;
    font-size: 0.7rem;
}

.boldSelected {
    font-weight: bold;
    color: white;
}

.fillWhite {
    color: white;
}

.padding {
    padding: 10px;
}

.roundImageMessageScreen {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.roundImageList {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.messageSender {
    color: #636363;
    font-size: 0.7rem;
    font-weight: 500;
    margin-left: 50px;
}

.inboxContainerLoader {
    top: 15% !important; // important statement is to override the spinners default position
    left: 14% !important;
}

@media screen and (min-width: 900px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 30% !important;
    }
    
}

@media screen and (min-width: 1024px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 26% !important;
    }
    
}

@media screen and (min-width: 1220px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 22% !important;
    }
    
}

@media screen and (min-width: 1440px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 18% !important;
    }
    
}

@media screen and (min-width: 1660px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 16% !important;
    }
    
}

@media screen and (min-width: 1880px) {
    .inboxContainerLoader {
        top: 15% !important; // important statement is to override the spinners default position
        left: 14% !important;
    }
    
}