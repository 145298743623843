@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.clickableText {
    font-weight: bold;
    font-size: 17px;
    display: inline;
    cursor: pointer;

    @include generic-hover;
}

.blue {
    color: $blue-primary;
}

.red {
    color: $red-alert;
}
