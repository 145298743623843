@import '../../styles/variables.scss';

.container {
    position: relative; // So that the 'active' dot can be absolutely positioned
    overflow: hidden; // Hide the 'active' dot until it comes into view
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 11px;
    height: 58.5px;
    margin: 4.5px 0;
    cursor: pointer;
    user-select: none;

    &.active {
        color: $blue-primary;
        .icon {
            :global(.a) {
                stroke: $blue-primary;
            }
        }
        .isActiveDot {
            bottom: 2px;
        }
    }
}

.icon {
    height: 24px;
    width: 24px;
    margin-bottom: 3.5px;
}

.isActiveDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $blue-primary;
    position: absolute;
    bottom: -4px;
    transition: bottom 0.2s ease-out;
}
