@media screen and (max-width: 900px) {
    .mainContainer {
        height: 100vh;
        width: 100vw;
        background-color: #1b5fea;
        padding: 20px;
        position: fixed;
        top: 0;
        right: 0;
    }

    .boxContainer {
        background-color: #f2f2f2;
        width: 100%;
        height: auto;
        padding: 30px;
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px rgba(12, 48, 227, 0.75);
    }

    .heading {
        color: #171716;
        font-size: 1.6rem;
        font-weight: bolder;
    }

    .paragraph {
        font-size: 1rem;
        color: #171716;
        padding-top: 10px;
    }

    .download {
        font-size: 1.3rem;
        color: #171716;
        font-weight: bolder;
    }

    .padding1 {
        padding-top: 20px;
    }

    .padding2 {
        padding-top: 7px;
    }
}

@media screen and (min-width: 901px) {
    .mainContainer {
        display: none;
    }
}
