.mainContainer {
    width: 200px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
}

.redLabel {
    color: red;
    font-weight: bold;
    cursor: pointer;
}

.blueLabel {
    color: blue;
    cursor: pointer;
    font-weight: bold;
}
