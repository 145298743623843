@import '../../../styles/variables.scss';

.passwordResetForm {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 350px;
    max-width: 100%;
}

.subtitle {
    text-align: center;
    font-size: 17px;
    color: $black1;
    opacity: 0.7;
}
