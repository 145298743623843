@import '../../styles/variables.scss';

@mixin messageBase {
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    border-radius: 25px;
    font-size: 0.9rem;
    max-width: 700px;
}

.videoContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.controlWrapper {
    display: flex;
    flex-direction: column;
}

.feedbackButton {
    margin-top: 2px;
    background-color: $blue-primary;
    text-align: center;
    font-weight: bold;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
}

.videoImg {
    width: 200px;
    height: auto;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
}

.videoImgPlayButton {
    margin: auto;
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;

    svg {
        width: 24px;
        height: 24px;
        stroke: white;
    }
}

.roundNameShort {
    height: 20px;
    width: 20px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
}

.roundImage {
    height: 20px;
    width: 20px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    object-fit: cover;
}

.individualMessageContainer {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    transition: opacity 0.2s;

    &:active {
        opacity: 0.7;
    }
}

.individualMessageContainerFirst {
    margin-top: 5px;
}

.individualMessageContainerNext {
    margin-bottom: 0;
}

.senderMessage {
    background-color: #1b5fea;
    color: white;
    @include messageBase;
}

.receiverMessage {
    background-color: #f5f5f5;
    color: black;
    @include messageBase;
}

.profileAlign {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flexContainer {
    display: flex;
}

.flaggedIcon {
    display: flex; 
    align-items: center;
    opacity: 0.20;
}

.flaggedIcon:hover {
    cursor: pointer;
    opacity: 0.50;
}