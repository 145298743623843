@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.headerBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 2px 4px #d9d9d954;
}

.headerBarTitle {
    font-size: 1.2rem;
    font-weight: bold;
}

.headerBarLink {
    box-shadow: 0px 2px 6px #b4b4b354;
    border-radius: 15px;
    color: #1b5fea;
    padding: 6px 12px 6px 12px;
    font-weight: bold;
    cursor: pointer;
}

.contentContainer {
    margin: auto;
    width: 70%;
    padding: 15px;
    margin-top: 10px;
}

.searchResultsContainer {
    padding: 10px;
}

.searchResult {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
}

.iconDetailsContainer {
    display: flex;
    align-items: center;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.searchResultsLabel {
    font-size: 1.5rem;
    font-weight: 600;
}

.userIcon {
    height: 35px;
    width: 35px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.requestSent {
    display: flex;
    margin-left: 5px;
    align-items: center;
}

.userNameContainer {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.addPending {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.addIcon {
    @include generic-hover;
    color: $blue-primary;
}

.requestPendingText {
    font-style: italic;
    color: #918f8f;
}
