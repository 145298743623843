@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.link {
    font-weight: bold;
    color: $blue-primary;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;

    @include generic-hover;
}

.unstyled {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
}
