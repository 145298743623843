@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.feedbackContainer {
    width: 100%;
    display: flex;
    height: 140px;
    cursor: pointer;
}

.feedbackTypeTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 1.1rem;
    color: #191716;
}

.feedbackSelectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.selectionRegion {
    width: 300px;
    background-color: #f5f5f5;
    height: 130px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.selectionLeft {
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.selectionRight {
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.optionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.labelOption {
    padding: 10px;
    font-size: 0.9rem;
    font-weight: bold;
}

.optionImageContainer {
    width: 80px;
    height: 70px;
    border-radius: 4px;
    background-color: #d9d9d9;
    padding: 4px;
}

.optionImageContainerCenter {
    width: 80px;
    height: 70px;
    border-radius: 4px;
    background-color: #d9d9d9;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    background-color: white;
    border-radius: 5px;
    width: 35%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainerCenter {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
