@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$square-size: 237px;
$square-margin: 28px;

.container {
    padding-top: 83px;
}

.squaresContainer {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(calc(#{$square-size} * 2) + calc(#{$square-margin} * 4));
    max-width: 100%;
}

.square {
    @include generic-hover;
    width: $square-size;
    height: $square-size;
    margin: $square-margin;
    background-color: $blue-primary;
    color: $white1;
    font-size: 21px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 18px;
    cursor: pointer;
}
