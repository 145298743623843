@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.subscriptionOptionsContainer {
    border-radius: 8px;
    display: flex;
}

.subscriptionOption {
    cursor: pointer;
    padding: 14px 16px;
    width: 50%;
    text-align: center;
    overflow: hidden;
    background-color: $white3;
    &.left {
        border-radius: 8px 0px 0px 8px;
    }
    &.right {
        border-radius: 0px 8px 8px 0px;
    }
    &.selected {
        background-color: #0066e3;
        color: $white1;
    }
    @include generic-hover;
}

.subscriptionOptionTitle {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 11px;
}

.subscriptionOptionPrimaryPrice {
    font-size: 21px;
    margin-bottom: 9px;
}

.subscriptionOptionSecondaryPrice {
    font-size: 14px;
    opacity: 0.7;
}
