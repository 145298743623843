@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.background {
    background-color: $white2;
    min-height: 100vh;
}

.settingsContainer {
    width: unquote('min(100vw, 655px)');
    background-color: $white1;
    min-height: 100vh;
    border-right: 1px solid $white4;
}

.settingsContainerPopout {
    width: 348px;
    background-color: $white1;
    min-height: 100vh;
    border-right: 1px solid $white4;
}

.headerBar {
    @include setting-panel;
    justify-content: unset;
    font-size: 21px;
    box-shadow: 0px 2px 4px #d9d9d954;
    letter-spacing: -0.63px;
    align-items: center;
}

.chevronContainer {
    display: flex;
    align-items: center;
}

.chevron {
    color: black;
    margin-right: 10px;
    @include generic-hover;
}

.closeIcon {
    @include generic-hover;
    margin-right: 10px;
}

.paddedContentContainer {
    padding: 24px 22px;
}
