@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.mainDiv {
    width: 600px;
    height: 100vh;
    background-color: '#ECECEC';
}

.mainHeader {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.heading {
    font-weight: bold;
    font-size: 1.4rem;
    padding-left: 10px;
}

.requestContainer {
    display: flex;
    justify-content: space-between;
}

.requestLeftContainer {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
}

.roundName {
    height: 32px;
    width: 32px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.requestMiddleContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 0.9rem;
}

.bold {
    font-weight: bold;
}

.name {
    font-weight: bold;
    color: grey;
    font-style: italic;
    font-size: 0.7rem;
}

.requestRightContainer {
    display: flex;
    padding-right: 20px;
    align-items: center;
}

.paddingGap {
    padding-left: 25px;
    cursor: pointer;
}

.cursorer {
    cursor: pointer;
}

.iconAccept {
    @include generic-hover;
    color: $green-add;
}

.iconReject {
    @include generic-hover;
    color: $red-reject;
}
