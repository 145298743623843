@import '../../styles/variables';
@import '../../styles/mixins';

.link {
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include generic-hover;
}

.icon {
    width: 20px;
    height: 20px;
    color: $blue-primary;
    margin-right: 8px;
}
