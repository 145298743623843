@import './variables.scss';

@mixin generic-hover {
    user-select: none;
    &:hover:not(:active) {
        opacity: 0.7;
    }
    transition: opacity 0.2s ease-out;
    cursor: pointer;
}

@mixin stackable-canvas {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
}

@mixin button-theme($color) {
    background-color: $color;
    color: white;
}

@mixin button-theme-inverted($color) {
    background-color: unset;
    color: $color;
    border-style: solid;
    border-width: 2px;
    border-color: $color;
}

@mixin button-themes {
    &.blue {
        @include button-theme($blue-primary);
        &.inverted {
            @include button-theme-inverted($blue-primary);
        }
    }

    &.gray {
        @include button-theme($gray-medium);
        &.inverted {
            @include button-theme-inverted($gray-medium);
        }
    }

    &.red {
        @include button-theme($red-alert);
        &.inverted {
            @include button-theme-inverted($red-alert);
        }
    }
}

@mixin setting-panel {
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    color: $black1;
    font-weight: bold;
    border-right: 1px solid $white4;
}
