.onboardingContainer {
    background-color: #1a64e2;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer {
    position: fixed;
    bottom: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    a {
        margin-left: 20px;
        margin-right: 20px;
        color: white;
        font-size: 12px;
    }
}
