.subContainer {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    height: 150px;
    padding: 10px;
    color: #191716;
    border-bottom: 1px solid #d9d9d954;
    padding-bottom: 40px;
}

.subContainerHeading {
    font-weight: bold;
    font-size: 1.2rem;
}

.padder {
    padding-top: 20px;
}

.container {
    display: flex;
    justify-content: space-between;
}

.mainBody {
    display: flex;
    width: 55%;
    padding: 10px;
    border-right: 1px solid #d9d9d954;
    background-color: #fefefe;
}
