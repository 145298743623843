@import '../../styles/variables';
@import '../../styles/mixins';

.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    padding-top: 20px;
    width: $sidebar-width;
    background: linear-gradient(180deg, #0056e1, #142d5a);
}

.brand {
    padding-left: 16px;
    padding-right: 20px;
    color: $white1;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.81px;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.sidebarItem {
    height: 33px;
    display: flex;
    align-items: center;
    color: $medium-white-on-primary;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left-style: solid;
    border-left-width: 4px;
    border-left-color: transparent;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.21px;

    &.activeSidebarItem {
        border-left-color: white;
        font-weight: bold;
    }

    @include generic-hover;
}

.icon {
    width: 20px;
    height: 20px;
    color: $medium-white-on-primary;
}

.settingsSidebarItem {
    position: absolute;
    bottom: 5px;
}

.onMessage {
    position: absolute;
    top: -5px;
    right: 95px;
    background-color: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    font-size: 12px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
}
