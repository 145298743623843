@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.roundIconButton {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include generic-hover;
    @include button-themes;
}

.small {
    width: 36px;
    height: 36px;
}
