@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.loginWithEmailForm {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 350px;
    max-width: 100%;
}

.stayLoggedIn {
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: auto;
    color: $green-success;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    line-height: 14px;

    svg {
        width: 20px;
        height: auto;
    }

    @include generic-hover;
}
