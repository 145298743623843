.mainBody {
    display: flex;
    width: 55%;
    border-right: 1px solid #d9d9d954;
    height: 41vmax;
    background-color: #fefefe;
    flex-direction: column;
}

.roundName {
    height: 32px;
    width: 32px;
    background-color: #d8d8d8;
    color: #767676;
    font-weight: bold;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.greenContainer {
    width: '100%';
    height: 180px;
    background-color: #6cc596;
    padding: 20px;
}
.containerHeading {
    font-weight: bold;
    font-size: 1.4rem;
}

.subHeading {
    display: flex;
    align-items: center;
    padding-top: 10px;
}
.padder {
    cursor: pointer;
    padding-top: 2px;
}

.myPadder {
    padding-top: 10px;
}
.referralContainer {
    height: 75px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonStyle {
    padding: 10px 20px 10px 20px;
    background-color: #1b5fea;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.myPadder2 {
    padding: 15px;
}

.header {
    font-weight: bold;
    font-size: 1.3rem;
}
.listViewItemContainer {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #ececec;
}
