@import './variables.scss';

html,
body {
    min-height: 100vh;
    min-width: 100vw;
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}

body {
    margin: 0;
    font-family: $default-font;
    box-sizing: border-box;
    background-color: #fefefe;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    max-width: 100%;
}

.ui.ui.ui {
    // Use to override any semantic styles. These can still be overridden later with !important or an ID selector
    * {
        font-family: $default-font;
    }
}

a {
    text-decoration: none;
}

h2 {
    font-size: 27px;
    margin: 8px 0;
    line-height: 1.28571429em;
    letter-spacing: -0.81px;
}

p {
    margin: 0 0 1em;
}

*,
:before,
:after {
    box-sizing: inherit;
}

.Toastify__toast.Toastify__toast {
    // Override default toast styles. Can still be overridden with !important;
    font-family: $default-font;
}
