.buttonsContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    width: 300px;
}

.title {
    padding: 20px;
    font-weight: bold;
    color: #777676;
}

.customHeading {
    font-size: 1.2rem;
    font-weight: bold;
    color: red;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    cursor: pointer;
}

.paddingLeft {
    padding-left: 15px;
    cursor: pointer;
}
