@import '../styles/variables.scss';

.authenticatedContainer {
    margin-left: $sidebar-width;
    width: calc(100vw - #{$sidebar-width});
    height: 100vh;
}

*:visited {
    color: $blue-primary;
}
