@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.mainHeader {
    display: flex;
    align-items: center;
    height: 60px;
    width: 55%;
    padding: 10px;
    border-bottom: 1px solid #d9d9d954;
    background-color: #fefefe;
    z-index: 1;
    box-shadow: 2px 4px 8px #d9d9d954;
}
.mainBody {
    display: flex;
    width: 55%;
    padding: 10px;
    border-right: 1px solid #d9d9d954;
    height: 41vmax;
    background-color: #fefefe;
}

.globalStylingForAccount {
    display: flex;
    width: 100%;
    background-color: #faf9f9;
}

.changePasswordHeader {
    display: flex;
    align-items: center;
    height: 60px;
    width: 30%;
    padding: 10px;
    box-shadow: 0px 2px 4px #d9d9d954;
    background-color: #fefefe;
}

.backTitle {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 10px;
}

.accountBody {
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.changeProfileButton {
    color: #1b5fea;
    font-weight: bold;
    cursor: pointer;
}
.cursorPointer {
    cursor: pointer;
}

.mainBodyContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.widthAdjustement {
    width: 50%;
}

.changepasswordContainer {
    border: 1px solid #dadada;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.changeTitle {
    font-weight: bold;
    font-size: 1.1rem;
    color: #1b5fea;
    cursor: pointer;
}

.deleteuserContainer {
    border: 1px solid #dadada;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.deleteUserTitle {
    font-weight: bold;
    font-size: 1.1rem;
    color: #ec0819;
    cursor: pointer;
}

.passwordContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fefefe;
}
.bodyText {
    font-size: 17px;
    color: #191716;
    overflow-y: auto;
    height: calc(100vh - 58px);
    padding: 16px;
    white-space: pre-line;
}

.freePanel.freePanel {
    color: $blue-primary;
}

.freePanelIcon {
    color: rgb(81, 170, 117);
}

.logoutPanel.logoutPanel {
    color: red;
}

.contentTitle {
    font-size: 21px;
    font-weight: bold;
}

.subscriptionSettingsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 17px;
}

.subscriptionSettingTitle {
    font-weight: 600;
    width: 140px;
}

.subscriptionSetting {
    white-space: pre-line;
    width: 180px;
}

.hr {
    border-top: $gray-medium;
}

.center {
    display: flex;
    justify-content: center;
}

.subscriptionModalOptions {
    display: flex;
    justify-content: space-between;
}
