$default-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$white1: #fefefe;
$white2: #faf9f9;
$white3: #f5f5f5;
$white4: #ececec;
$white5: #d9d9d9;
$medium-white-on-primary: #e2ebfc;
$cream: #f2f2f2;
$blue-primary: #1b5fea;
$gray-medium: #777676;
$black-text: #171717;
$black1: #191716;
$green-success: #3bb273;
$green-add: #79bb8b;
$green-draw: #62c18f;
$yellow-warning: #f9f65e;
$red-alert: #ec0819;
$red-reject: #ce4334;

$sidebar-width: 140px;
