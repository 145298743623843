.outerSelect {
    width: 55px;
    height: 28px;
    background-color: #6cc696;
    border-radius: 50px;
    cursor: pointer;
}
.outerUnselect {
    width: 55px;
    height: 28px;
    background-color: grey;
    border-radius: 50px;
    cursor: pointer;
}

.innerContainer {
    padding: 3px;
    display: flex;
    justify-content: space-between;
}

.whiteBox {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
}
