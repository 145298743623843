@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

$toolbar-width: 44px;
$toolbar-margin: 0px 6.5px;
$timeline-container-width: 600px;
$timeline-height: 48px;

.videoAnnotator {
    padding: 20px;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.outerCanvasContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.canvasContainer {
    position: relative;
    z-index: 2;
    align-self: center;
}

.fillHeight {
    background-color: white;
    z-index: 1;
}

.toolbarRight {
    width: $toolbar-width;
    margin: $toolbar-margin;
}

.toolbarLeft {
    // As of right now there are no tools on the left, this is just to keep the video container centered
    width: $toolbar-width;
    margin: $toolbar-margin;
}

.backgroundVideo {
    display: none;
}

.recorderCanvas {
    @include stackable-canvas;
    visibility: hidden;
    pointer-events: none;
}

.sourceVideoCanvas {
    @include stackable-canvas;
    z-index: 0;
}

.imageCanvas {
    @include stackable-canvas;
    z-index: 1;
    pointer-events: none;
    border-radius: 8px;
    margin: 10px;
}

.imageCanvasFull {
    @include stackable-canvas;
    z-index: 2;
    pointer-events: none;
    border-radius: 0;
    margin: 0;
}

.webcamCanvas {
    @include stackable-canvas;
    z-index: 2;
    pointer-events: none;
    border-radius: 8px;
    margin: 10px;
}

.webcamCanvasFull {
    @include stackable-canvas;
    z-index: 2;
    pointer-events: none;
    border-radius: 0;
    margin: 0;
}

.controlContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.playButtonContainer {
    background-color: white;
    width: 61px;
    z-index: 2;
    position: absolute;
    cursor: default;
}

.timelineContainer {
    display: flex;
    width: $timeline-container-width;
    margin: 0 auto;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        background: transparent;
        pointer-events: none;
    }

    visibility: hidden;
    cursor: grab;
    user-select: none; // This prevents weird scroll behavior when grabbing and moving the timeline
}

.timelineTime {
    width: 100%;
    text-align: center;
    color: $gray-medium;
    font-size: 11px;
    margin-bottom: 4px;
}

.triggerSpacer {
    min-width: 50%;
}

.centerLine {
    position: absolute;
    height: calc(#{$timeline-height} + 8px);
    width: 2px;
    left: calc(50% - 2px + #{$sidebar-width} / 2);
    margin-top: -4px;
    background-color: $blue-primary;
    border: 1px solid white;
    border-radius: 1px;
    box-sizing: content-box;
}

.drawToolOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    position: relative;
    left: -100%;
    transition: left 0.2s ease-out;

    &[data-is-enabled='true'] {
        left: 0;
    }
}

.clearAll {
    @include generic-hover;
    margin-top: 15px;
    color: $blue-primary;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
}

.playbackVideo {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 200px !important;
}

.hiddenFileInput {
    display: none;
}

.innerModalContainer {
    margin: -10px;
}

.modalHeaderText {
    color: $gray-medium;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.21px;
}

.modalButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 240px;
    margin-top: 15px;
}

.labeledModalButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @include generic-hover;

    > *:hover {
        opacity: 1 !important; // Don't stack the opacity animation when hovering the button
    }
}

.modalButtonLabel {
    margin-top: 16px;
    color: $blue-primary;
    font-size: 16px;
}

.recordButtonsContainer {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.paddedModalPanel {
    padding-left: 80px;
    padding-right: 80px;
}

.deleteModalHeaderText {
    font-size: 17px;
    width: 340px;
    margin-bottom: 22px;
}

.recordingBorderAnimation {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    background-color: transparent;
    transition: background-color 0.3s;

    // --black: #191716;
    // --white: #fefefe;
    // --blue: #1b5fea;
    // --red: #ec0819;
    // --green: #6cc596;
    // --yellow: #f9f65e;
    // --medium-gray: #777676;
    // --video-height: 100%;
    // --gradient-color: #f39414;
    // --gradient-color-2: var(--red);
    // --gradient-diameter: calc(var(--video-height) * 1.33);

    // background:
    //     radial-gradient(closest-side, var(--gradient-color), transparent),
    //     radial-gradient(closest-side, var(--gradient-color-2),
    //     transparent),
    //     transparent;
    // background-position:
    //     top calc(var(--gradient-diameter) / -2) left calc(var(--gradient-diameter) / -2),
    //     bottom calc(var(--gradient-diameter) / -2) right calc(var(--gradient-diameter) / -2),
    //     center;
    // background-size:
    //     var(--gradient-diameter) var(--gradient-diameter),
    //     var(--gradient-diameter) var(--gradient-diameter),
    //     cover;
    // background-repeat: no-repeat, no-repeat, no-repeat;

    // animation-name: travel;
    // animation-iteration-count: infinite;
    // animation-duration: 4s;
    // animation-timing-function: linear;
    // animation-direction: reverse;
}

// @keyframes travel {
//     /* keyframe percentages depend on video aspect ratio */
//     0%  {
//         background-position:
//             top calc(var(--gradient-diameter) / -2) left calc(var(--gradient-diameter) / -2),
//             bottom calc(var(--gradient-diameter) / -2) right calc(var(--gradient-diameter) / -2),
//             center;
//     }
//     32% {
//         background-position:
//             bottom calc(var(--gradient-diameter) / -2) left calc(var(--gradient-diameter) / -2),
//             top calc(var(--gradient-diameter) / -2) right calc(var(--gradient-diameter) / -2),
//             center;
//     }
//     50% {
//         background-position:
//             bottom calc(var(--gradient-diameter) / -2) right calc(var(--gradient-diameter) / -2),
//             top calc(var(--gradient-diameter) / -2) left calc(var(--gradient-diameter) / -2),
//             center;
//     }
//     82%  {
//         background-position:
//             top calc(var(--gradient-diameter) / -2) right calc(var(--gradient-diameter) / -2),
//             bottom calc(var(--gradient-diameter) / -2) left calc(var(--gradient-diameter) / -2),
//             center;
//     }
// }
