.closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.panel {
    padding: 25px 30px;
    &.horizontalPanelPadding {
        padding: 25px 80px;
    }
}
