@import '../../styles/variables.scss';

$height: 68px;
$font-size: 17px;

.outerContainer {
    position: relative;
    max-height: $height;
    width: 100%;
    margin: 8px 0;
    border-radius: 8px;
    &:focus-within {
        box-shadow: 0px 1px 4px #b4b4b354;
    }
}

.innerContainer {
    width: 100%;
    height: $height !important;
    border-radius: inherit;
    // When the input is focused or has text, shrink the label
    // Semantic wraps a div around the actual input, so we need to target :focus-within
    &:focus-within + .floatingLabel,
    &[data-has-text='true'] + .floatingLabel {
        top: 10px;
        font-size: 14px;
    }
    input {
        letter-spacing: -0.51px !important;
        font-size: $font-size;
        border-radius: inherit !important;
        // Move the cursor lower in the input box to match the mockups
        padding-top: 15px !important;
        padding-bottom: 4px !important;
        color: $black-text !important;
        // Override the default html `size` attribute
        width: 0px !important;
    }
}

.floatingLabel {
    position: absolute;
    pointer-events: none;
    top: 24px;
    font-size: $font-size;
    left: $font-size;
    transition: 0.2s ease all;
    opacity: 0.7;
    color: $gray-medium;
    letter-spacing: -0.51px;
}

.passwordIcon {
    pointer-events: auto !important;
    user-select: none !important;
    cursor: pointer !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    width: 45px !important;
    svg {
        margin-right: 10px;
    }
}
